
main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.little {
    max-height: 32px;
}

.hidden {
    display: none;
}