.modal-large {
    inset: 1%;
     max-height: 90%;
}

.modal-medium {
    left: 10%;
    right: 10%;
    top: 50px;
     max-height: 90%;
}

.modal-small {
    left: 33%;
    right: 33%;
    top: 50px;
    max-height: 90%;
}

.custom-modal {
    position: absolute;
    background: #fff;
     border: 1px solid #ccc;
     overflow: auto;
     WebkitOverflowScrolling: touch;
     outline: none;
     padding: 20px;
     max-height: 90%;
}


.modal-transparent {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    overflow: auto;
    WebkitOverflowScrolling: touch;
    outline: none;
    padding: 20px;
    left: 33%;
    right: 33%;
    top: 50px;
    max-height: 90%;
}

.modal-footer {
    display: block !important;
}

.modal-footer-close {
    float: right;
}