.expression-list {

}

.expression-thumbnail-action{
    text-align: center;
    border-radius: 3px;
    border: solid 1px #000;
    margin: 5px;
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
}

.expression-editor {

}