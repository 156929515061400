
.root {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

input {
    margin-bottom: 10px;
}

.error_message {
    background-color: #e45;
    color: #fff;
    padding: 10px;
    margin: 0px;
    margin-bottom: 10px;
}

.login-field {
  width: 100%;
}