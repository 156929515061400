.numeric-info-simple {
}

.numeric-info-simple-root {
    display: block;
    width: 100%;
    height: 100%;
}

.numeric-info-simple-header {
    height: 100%;
    float: left;
}

.numeric-info-simple-value {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
}

.numeric-info-simple-title {
    font-size: 20pt;
    font-weight: 600;
}

.numeric-info-simple-sub-title {
    font-size: 12pt;
    font-weight: 200;
}

.numeric-info-simple-content {
    font-size: 30pt;
    font-weight: 900;
}


.numeric-info-image {

}

.numeric-info-simple-root{
    display: block;
    width: 100%;
    height: 100%;
}

.numeric-info-image-icon-holder {
    height: 100%;
    float: left;
    font-size: 60pt;
}


.numeric-info-image-header {
    height: 100%;

}

.numeric-info-image-title {
    font-size: 18pt;
    font-weight: 600;
}

.numeric-info-image-sub-title {
    font-size: 12pt;
    font-weight: 200;
}

.numeric-info-image-content {
    font-size: 24pt;
    font-weight: 900;
}


.numeric-info-image-icon {

}


.numeric-info-holder{

}

