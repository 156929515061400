

.list-thumbnail-action {
    border: solid 1px black;
    border-radius: 3px;
    cursor: pointer;
    padding: 3px;
    margin-top: 2px;
    margin-right: 5px;
    font-size: 14pt;
}