.pattern {
    display: flow-root;
    background-color: #ddd;
    border: 1px solid #000;
    border-radius: 3px;
    padding: 10px;
}

.filter-liste-icons {
    border: solid 1px black;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    margin-top: 2px;
}

.pattern-holder {
    margin: 5px;
}


.pattern-resume {

}


.pattern-action {
    float: right;
}

.pattern-action > a > i {
    font-size: 24px;
    padding: 5px;
}

.pattern-action > a {
    background-color: #eee;
    margin-right: 5px;
    border: 1px solid #000;
    border-radius: 3px;
    cursor: pointer;
}