.datepicker-btn {
    border-radius: 0px;
    border: solid 1px #000;
    background-color: #fff;
    padding: 5px;
    font-size: 14px;
}

.datepicker-window {

    border: solid 1px #000;
    position: fixed;
    z-index: 1000;
    background-color: #eee;
    text-align: center;
}

.datepicker-table-body {

}

.datepicker-table-body-btn-current-day {
    color: red;
    font-weight: 900;
}

.datepicker-table-body-btn-selected {
    background-color: lightblue;
}

.datepicker-table {
    margin: 5px;
    display: block;
}

.datepicker-red {
    color: #e53;
}

.datepicker-action-line {
    margin-top: 5px;
    text-align: center;
}

.datepicker-action-line > a {
    font-size: 24px;
    padding: 2px;
    border: solid 1px #555;
}

.datepicker-action-line > span {
    margin-top: 10px;
}

.datepicker-action-null > a {
    margin-bottom: 0px;
    border: solid 1px #000;
}

.datepicker-action-footer > a {
    margin-bottom: 0px;
    border: solid 1px #000;
}

.datepicker-action-footer {
    margin-bottom:  5px;
}

.datepicker-td {
    margin: 1px;
}


.datepicker-table-body-btn:hover {
    font-weight: 900;
}

.datepicker-table-body-btn {
    padding: 5px;
    margin: 0px;
    border: solid 1px #555;
    width: 30px;
}

.periodpicker {

}
.periodpicker > select,
.periodpicker > input[type=number] {
    width: 100px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.daterange-picker > * {
}

.daterange-picker{
    display: flex;
}

.daterange-picker > .periodpicker {
    display: flex;
    margin: auto;
}

.date-picker {
    display: flex;
}

.date-picker-select {

}

.date-picker-content {

}

.form-select {
    width: auto !important;
}

