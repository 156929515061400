
.operation-filter {
}

.operation-filter-closed{
}

.operation-filter-opened{
}

.operation-filter-content {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.operation-filter-input {
    display: flex;
    margin-bottom: 5px;
}

.operation-filter-input>span{
    margin-left: 10px;
}

.operation-filter-input>label{
    position: relative;
    top: 5px;
}


.operation-filter-validate {
    margin-top: 10px;
}

.operation-filter-input * > input[type="text"] {
    min-width: 512px;
    margin-bottom: 0px;
}

.operation-filter-input * > input[type="number"] {
    max-width: 128px;
    margin-bottom: 0px;
}

.operation-filter-validate>a {
    padding: 5px;
}

.operation-filter-header>h5{
    display: inline;
    position: relative;
    top: -5px;
}


.operation-filter-summary{

}

.operation-filter-toggle{
    font-size: 36px;
    margin-right: 10px;
    position: relative;
}