.provision {

}

.provision-field{
    margin-bottom: 5px;
    display: flex;
}

.provision-list {

}

.provision-list-item{

}

.provision-list-item-icon {

}

.provision-field-label {
    margin-right: 10px;
}