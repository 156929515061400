.btn-category {
    border-radius: 0px;
    border: solid 1px #000;
    background-color: #fff;
    padding: 5px;
    font-size: 14px;
}

.categories-inc-excl {
    display: flex;
}