
@media only screen and (max-width: 600px) {
    .tile-border {
        padding: 10px 0px 10px 0px;
    }
    .tile {
        padding: 0px;
    }
}

@media only screen and (min-width: 600px) {
    .tile-border {
        padding: 10px;
    }
    .tile {
        padding:10px;
    }
}

.tile {
    padding:10px;
    background-color: #fff;
}

.tile-header {
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    margin: 10px;
    min-height: 38px;
    font-size: 18pt;
}

.tile-header-action {
    display: block;
    margin: 0px;
    padding: 0px;
    top: 0px;
    float: right;
    margin-right: 10px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}

.tile-header-action>a {
    padding: 0px;
    color: black; 
}

.tile-content {
    overflow-y: auto;
    overflow-x: none;
    max-height: 90vh;
}

.tile-editor-field {
    display: flex;
    margin-bottom: 10px;
}

.tile-editor-sub {
    padding: 10px;
    border: solid 1px rgb(192, 192, 192);
    border-radius: 3px;
}

.dashboard-title-add {
    border: solid 1px rgb(158, 158, 158); 
    border-radius: 3px;
    color:rgb(15, 110, 78);
    position: absolute;
    background-color: beige;
    top: 20px;
    right: 20px;
    font-size: 20pt;
    cursor: pointer;
}


.dashboard-title-list {
    border: solid 1px rgb(158, 158, 158); 
    border-radius: 3px;
    color:rgb(15, 110, 78);
    position: absolute;
    background-color: beige;
    top: 20px;
    left: 20px;
    font-size: 20pt;
    cursor: pointer;
}