.graph-content {
    border: solid 0.5px rgb(197, 197, 197);
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
}

canvas {
    background-color: #fff;
}

.graph-content-remove {
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 2px;
    float: right;
    background-color: #eee;
}

.graph-content-field {
    display: flex;
    margin-bottom: 5px !important;
}

.graph-field {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 2.5px;
    padding-bottom: 5px;
}

.line-color {
    height: 32px;
    width: 32px;
    border: solid 1px #000;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
}

.line-entry {
    margin-right: 5px;
    margin-left: 5px;
}

.line-action {
    margin-bottom: 10px;
    margin-top: 5px;
}

.color-button {
    height: 32px;
    width: 32px;
    border: solid 1px #000;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
}

.color-chooser-colors {
    position: fixed;
    z-index: 1000;
}

.color-chooser-button {
    height: 32px;
    width: 32px;
    border: solid 1px #000;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
}