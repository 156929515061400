.dashboard-list {
    display: flex;
    position: fixed;
    top: 56px;
    left:0px;
    bottom: 0px;
    right: 0px;
}

.dashboard-list-leaf{
    cursor: pointer;
    display: flex;
    padding: 4px;
}

.dashboard-list-leaf-item {
}

.dashboard-list-leaf-dropdown{
    
}
.dashboard-list-leaf-dropdown>a{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: black;
}

.dashboard-list-leaf-hover {
    border: solid 1px #e0f3ff;
    background-color: #e0f3ff;
}

.dashboard-list-node-hover{
    border: solid 1px #c5c5c5;
    background-color: #c5c5c5;
}

.dashboard-list-node{
    
    padding: 5px;
}

.dashboard-list-node-children{
    list-style-type: none;
    padding-right: 5px;
    padding-left: 20px;
    cursor: pointer;
}
.dashboard-list-node-children-root{
    list-style-type: none;
    padding-right: 5px;
    padding-left: 5px;
    cursor: pointer;
}

.dashboard-list-node-label{

}

.dashbords-menu {
    height: 100%;
    overflow: auto;
    position: relative;
    min-width: 150px;
}

.dashbords-menu>h5 {
    margin-left: 10px;
    margin-top: 10px;
}

.dashbords-menu-add{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    background-color: aliceblue;
    border-color: #f1f4f6;
    color: black;

}

.dashboards-divider {
    height: 100%;
    width: 1px;
    background-color: black;
}

.dashboard {
    width: 100%;
    padding: 20px;
    overflow-y: auto;background: rgb(238,238,244);
    /*background: linear-gradient(-0deg, rgb(240, 240, 247) 22%, rgb(216, 219, 223) 30%);*/
    background-color: #f1f4f6;

}

.dashboard-list-node-children::before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: #e0f3ff;
    position: absolute;
    left: 20px;
    top: 0;
    border-radius: 15px;
    position: relative
  }