.graph-page-root {
}

.graph-page-root-grid {
  display: grid;
  align-items: center;
}


.graph-page-root-add {
    display: flex;
    width: 100%;
}