.operation-thumbnail-selected {
    background-color: #23e;
}

.operation-summary {
    position: fixed;
    top: 50px;
    left: 50px;
    background-color: #4e4b;
    padding: 10px;
}

.operation-summary-info {
    margin-top: 5px;
    margin-bottom: 5px;
}

.operation-thumbnail {
    transition: all .2s ease-in-out;
}

.operation-thumbnail:hover {
    background-color: #3ea;
}

.operation-thumbnail-action-holder>a {
    text-align: center;
    border-radius: 3px;
    border: solid 1px #000;
    margin: 5px;
}

.operation-thumbnail-action {
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
}

.operation-summary > div > a{
    border: solid 1px #000;
    background: #23d5;
    color: #000;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.operation-header>th>a{
    font-weight: 900;
    cursor: pointer;
}

.operation-table-sort-indicator {
    font-size: 18px;
    margin-right: 3px;
    position: relative;
}


.operation-thumbnail>td {
    vertical-align: middle;
    display: table-cell;
    padding-top: 0px;
    padding-bottom: 0px;
}




