

.page-options-modal{
    position: fixed;
    top: 15px;
    right: 15px;
    min-width: 200px;
    background-color: aliceblue;
}

.page-options-button {
    color: black;
    margin-right: 10px;
    font-size: 20pt;
    cursor: pointer;
}

.page-options-autooptions-root{
    padding: 10px;
}