

.account-thumbnail {
    background-color: #aaa;
    margin: 10px;
    border: 1px solid #333;
    padding: 5px;
    position: absolute;
    border-radius: 1px;
    min-width: 30%;
}

.account-thumbnail-holder {
    background-color: #ccc;
    display: flex;
}

.account-thumbnail-solde {
    color: #835;
    font-size: 14pt;
    right: 5px;
    top: 5px;
    position: absolute;
}

account-thumbnail-name {
    color: #333;
    font-size: 17pt;
    position: absolute;
}