.category-thumbnail-action{
    font-size: 20px;
    cursor: pointer;
}

.category-thumbnail-action-holder>a {
    margin-right: 10px;
    max-width: 200px;
    border: solid 1px black;
    padding: 2px;
    border-radius: 3px;
    background-color: #ccc;
}