.graph-thumbnail {
    padding: 15px;
}


.graph-thumbnail-header {
    width: 100%;
    display: block;
    background-color: bisque;
    border: solid 1px #000;
    border-bottom: none;
    border-radius: 3px;
}

.graph-thumbnail-header-title {
    font-size: 24px;
    margin-left:20px;
    margin-top: 5px;
    margin-right: 5px;

}

.graph-thumbnail-header-action {
    display: block;
    margin: 0px;
    padding: 0px;
    top: 0px;
    float: right;
    margin-right: 10px;
    font-size: 24px;
    color: black;
    cursor: pointer;
}

.graph-thumbnail-header-action > a {
    padding: 0px;
    color: black; 
}


.graph-thumbnail-content {
    border: solid 1px black;
    border-top: none;
    padding: 10px;
    border-radius: 3px;
}


.graph-thumbnail-date-select {

    border: solid 1px black;
    border-top: none;
    border-bottom: none;
    background-color: aliceblue;
}

.graph-thumbnail-date-select-button {
    font-size: 24px;
    border: 1px solid black;
    border-radius: 3px;
    margin:5px;
}