.nav-link {
    cursor: pointer;
}

.nav-item {
    margin-right: 10px;
}

.navbar {
    background-color: #ea3 !important;
    z-index: 1;
}
